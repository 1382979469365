import React from 'react';
import { MetricsElement } from 'react-metrics';
import Img from 'gatsby-image';
import {
  GetAfreeQuoteButton,
  LinkArrowButtonSecondary,
} from 'components/Shared/Button/Button';
import ContentBox from 'components/Shared/ContentBox/ContentBox';
import SeparatorWithGradient from 'components/Shared/SeparatorWithGradient/SeparatorWithGradient';
import './Hero.scss';

const Hero = ({ data }) => (
  <div className={`hero-${data.size}`}>
    <Img
      className="hero__image"
      fluid={data.heroImage.childImageSharp.fluid}
      imgStyle={
        data.heroImagePosition ? { objectPosition: data.heroImagePosition } : {}
      }
      loading="eager"
      fadeIn={false}
    />
    <div className="hero__overlay">
      <ContentBox>
        <div className="row no-gutters">
        <div className="col-10 col-md-7 col-lg-7">
            <div className="hero__cta-card">
              <h1
                className="hero__title"
                dangerouslySetInnerHTML={{
                  __html: data.title,
                }}
              />
              <div className="hero__subtitle-container">
                <h3
                  className="hero__subtitle"
                  dangerouslySetInnerHTML={{
                    __html: data.subtitle,
                  }}
                />
                {data.disclaimer && (
                  <div
                    className="hero__disclaimer"
                    dangerouslySetInnerHTML={{
                      __html: data.disclaimer,
                    }}
                  />
                )}
                <div className="hero__buttons-container">
                  {data.primaryButton && (
                    <MetricsElement>
                      <GetAfreeQuoteButton
                        className="hero__cta-button"
                        data-metrics-event-name="hero_cta_click"
                        data-metrics-label={data.primaryButton.text}
                        to={data.primaryButton.url}
                      >
                        {data.primaryButton.text}
                      </GetAfreeQuoteButton>
                    </MetricsElement>
                  )}
                  {data.secondaryButton && (
                    <LinkArrowButtonSecondary to={data.secondaryButton.url}>
                      {data.secondaryButton.text}
                    </LinkArrowButtonSecondary>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </ContentBox>
    </div>
    {data.separator == 'Yes' && <SeparatorWithGradient />}
  </div>
);

export default Hero;
