import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import ContentBox from 'components/Shared/ContentBox/ContentBox';
import Img from 'gatsby-image';
import './PreFooter.scss';

const PreFooter = () => {
  const { data } = useStaticQuery(
    graphql`
      query {
        data: preFooterYaml {
          text
          image {
            childImageSharp {
              fluid(maxWidth: 1440) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    `
  );
  return (
    <div className="aaa-pre-footer">
      <Img
        className="aaa-pre-footer__image"
        fluid={data.image.childImageSharp.fluid}
      />
      <div className="aaa-pre-footer__content-wrapper">
        <div className="aaa-pre-footer__content">
          <ContentBox>
            <h2
              className="aaa-pre-footer__text"
              dangerouslySetInnerHTML={{
                __html: data.text,
              }}
            />
          </ContentBox>
        </div>
      </div>
    </div>
  );
};

export default PreFooter;
