import React from 'react';

// import AccordionElement from 'components/AccordionElement/AccordionElement';
import ContentBox from 'components/Shared/ContentBox/ContentBox';
import { default as Link } from 'components/Shared/Link';
import SeparatorWithGradient from 'components/Shared/SeparatorWithGradient/SeparatorWithGradient';
import './ResourcesContainer.scss';

const ResourcesLinks = ({ resources }) => {
  if (resources) {
    return resources.map((resource, i) => (
      <li key={i}>
        <Link to={resource.file.publicURL}>{resource.title}</Link>
      </li>
    ));
  }
  return null;
};

const ResourcesContainer = ({ data }) => (
  <div className="resources-card-container">
    <ContentBox>
      {data.cards.map((card, i) => (
        <div className="resources-card" key={i}>
          <div className="resources-card__row-header">
            <div className="resources-card__row-header__title">
              {card.title}
            </div>
            <div className="resources-card__row-header__dash">&mdash;</div>
            <div className="resources-card__row-header__subtitle">
              {card.subtitle}
            </div>
          </div>
          <div className="resources-card__item-panel">
            <div
              className="resources-card__description"
              dangerouslySetInnerHTML={{
                __html: card.description,
              }}
            />
            <ul className="resources-card__links">
              <ResourcesLinks resources={card.resources} />
            </ul>
          </div>
          <SeparatorWithGradient className="resources-card__separator" />
        </div>
      ))}
    </ContentBox>
  </div>
);

export default ResourcesContainer;
